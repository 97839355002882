nav {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    background-color: #ffff;
    box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.26);
    transition: 0.5s;

}

nav.active{
    position: fixed;
    width: 100%;
    z-index: 10000002;
}

nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


nav .nav-content .logo img{
    width: 170px;
    height: 75px;
    object-fit: contain;
}


nav .nav-content .nav-eles ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

nav .nav-content .nav-eles ul li{
    user-select: none;
    cursor: pointer;
}

nav .nav-content .nav-eles ul li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.5s;
    position: relative;
}

nav .nav-content .nav-eles ul li a:hover{
    color: #1b9cda;
}

nav .nav-content>a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
    border-radius: 50px;
    background-color: #1b9cda;
    color: #fff;
}


#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #000;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-eles {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 10000005;
    }

    nav .nav-content .nav-eles ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

    }

    
    nav .nav-content .nav-eles{
        display: none;
    }

    nav .nav-content .nav-eles ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }


    nav .nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


    nav .nav-content>a {
        display: none;
    }
}

