.contact{
    padding-top: 100px;
    padding-bottom: 100px;
}

.contact .contact-content{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


.contact .contact-content .email-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.contact .contact-content .email-info i{
    font-size: 62px;
    color: #000;
}




.contact .contact-content .email-info div h3{
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.78;
    margin-bottom: 13px;
    color: #000;
}

.contact .contact-content .email-info div a{
    color: #1b9cda;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.17;

}




.contact .contact-content .form{
    min-height: 73vh;
    background-color: #fff;
    text-align: center;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
    padding: 20px;
    margin-bottom: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.contact .contact-content .form h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 40px;
}

.contact .contact-content .form p{
    line-height: 1.74;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    visibility: visible;
    font-family: "Inter", sans-serif;
    color: #696969;
    position: relative;
}

.contact .contact-content .form form{
    width: 100%;
}

.contact .contact-content .form form .inputs{
    min-height: 94px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
}

.contact .contact-content .form form .inputs input{
    width: 100%;
    height: 55px;
    padding-left: 7px;
    border: none;
    outline: none;
    background-color: #f6f6f6;
}

.contact .contact-content .form form .inputs textarea{
    width: 100%;
    height: 150px;
    padding-left: 7px;
    border: none;
    outline: none;
    background-color: #f6f6f6;
    padding-top: 10px;
}

.contact .contact-content .form form button{
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 48px;
    background-color: #1b9cda;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    border-radius: 50px;
}


@media(max-width:950px){
    .contact .contact-content {
        min-height: 20vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .contact {
        padding-top: 0;
    }

    .contact .contact-content .form form .inputs {
        min-height: 94px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 7px;
    }

    .contact .contact-content .email-info div a {
        color: #d2a98e;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.17;
    }
}