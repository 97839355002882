.our-solutions{
    background-image: url('../images/oursolution.webp');
    background-size: cover;
    background-attachment: fixed;
    min-height: 65vh;
    position: relative;
    margin-bottom: 100px;
    margin-top: 100px;
}

.our-solutions .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000b0;
}


.our-solutions .our-solutions-content .text{
    position: relative;
    z-index: 1;
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.our-solutions .our-solutions-content .text h4{
    color: #ababab;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-top: -5px;
    padding-left: 38px;
}

.our-solutions .our-solutions-content .text h1 {
    position: relative;
    font-weight: 600;
    line-height: 1.4;
    width: 77%;
    color: #fff;
    padding-left: 34px;
    font-size: 35px;
    border-left: 4px solid #1b9cda;
}

.our-solutions .our-solutions-content .text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    background-color: #1b9cda;
    border-radius: 4px;
    color: #000;
    margin-top: 40px;
    border-radius: 50px;
    color: #fff;
}



@media(max-width:950px){
    .our-solutions .our-solutions-content .text h1 {
        position: relative;
        font-weight: 600;
        line-height: 1.4;
        width: 100%;
        color: #fff;
        padding-left: 34px;
        font-size: 28px;
        border-left: 4px solid #d2a98e;
    }
}