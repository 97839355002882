.our-blogs{
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #f6f6f6;
}



.our-blogs .our-blogs-content .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.our-blogs .our-blogs-content .header h3{
    color: #ababab;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-top: -5px;
}

.our-blogs .our-blogs-content .header h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 40px;
    width: 60%;
}


.our-blogs .our-blogs-content .blog{
    width: 400px;
    box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
    margin-bottom: 40px;
}


.our-blogs .our-blogs-content .blog img{
    width: 100%;
    padding: 18%;
    border-radius: 9px;
    background-color: #c1dbef;
}

.our-blogs .our-blogs-content .blog .text{
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
}

.our-blogs .our-blogs-content .blog .text h1{
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 2px;
    color: #333 !important;
    font-size: 17px;
    margin-bottom: 12px;
}

.our-blogs .our-blogs-content .blog .text h2{
    color: #1b9cda;
    margin-bottom: 2px;
    line-height: 1.74;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    position: relative;
}

.our-blogs .our-blogs-content .blog .text p{
    line-height: 1.74;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #696969;
    position: relative;
    margin-top: 20px;
    margin-bottom: 25px;
}

.our-blogs .our-blogs-content .blog .text a{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #00000026;
    color: #1b9cda;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: center;
    font-weight: 500;
}
.our-blogs .our-blogs-content .swiper-slide{
    width: fit-content;
}

.our-blogs .blogs-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 60px;
}

.our-blogs .blogs-footer h4{
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
    color: #333333;
}

.our-blogs .blogs-footer h4 a{
    color: #1b9cda;
}


@media(max-width:950px){
    .our-blogs .our-blogs-content .header h1 {
        color: #333333;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.3;
        font-size: 26px;
        width: 100%;
    }

        
    .our-blogs .our-blogs-content .blog{
        width: 100%;
        box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
        margin-bottom: 40px;
    }

    .our-blogs .our-blogs-content .swiper-slide{
        width: 100%;
    }

}