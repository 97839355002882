.why-choose-us{
    padding-top: 100px;
    padding-bottom: 100px;
}

.why-choose-us .header{
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.why-choose-us .header h3{
    color: #ababab;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-top: -5px;
}

.why-choose-us .header h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 40px;
    width: 60%;
}

.why-choose-us .reason{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
    padding: 21px;
    border-radius: 13px;
    min-height: 420px;
    justify-content: space-around;
}



.why-choose-us .reason>i{
    font-size: 80px;
    color: #1b9cda;
}

.why-choose-us .reason h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 12px;
    font-size: 24px;
}

.why-choose-us .reason p{
    font-size: 17px;
    line-height: 23px;
    min-height: 165px;
    color: #5a5a5a;
}

.why-choose-us .reason a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 9px;
    font-size: 16px;
    color: #1b9cda;
}


@media(max-width:950px){
    .why-choose-us .header h1 {
        color: #333333;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.3;
        font-size: 24px;
        width: 100%;
    }
}