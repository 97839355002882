
.careers .careers-content .all-jobs .header h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 34px;
    margin-bottom: 90px;
}

.careers .careers-content .all-jobs .job{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid #0000003b;
}

.careers .careers-content .all-jobs .job img{
    width: 250px;
    height: 100%;
    object-fit: cover;
    height: 195px;
}


.careers .careers-content .all-jobs .job .info h2{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 20px;
    margin-bottom: 25px;
}


.careers .careers-content .all-jobs .job .info p{
    font-size: 15px;
    width: 80%;
    line-height: 18px;
    color: #696969;
}

.careers .careers-content .all-jobs .job .info a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 35px;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
    border-radius: 50px;
    background-color: #1b9cda;
    color: #fff;
    font-size: 16px;
}



.careers .careers-content .form .inputs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;
}

.careers .careers-content .form .inputs input{
    width: 100%;
    margin-bottom: 20px;
    height: 45px;
    padding-left: 20px;
    border: 1px solid #0000005e;
    color: #000;
    font-size: 16px;
    outline: none;
    border-radius: 50px;
    padding-top: 6px;
}


.careers .careers-content .form .inputs textarea{
    width: 100%;
    margin-bottom: 20px;
    height: 227px;
    padding-left: 20px;
    border: 1px solid #0000005e;
    color: #000;
    font-size: 16px;
    outline: none;
    border-radius: 50px;
    padding-top: 20px;
}

.careers .careers-content .form button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
    border-radius: 50px;
    background-color: #1b9cda;
    color: #fff;
    outline: none;
    border: none;
    margin-top: 26px;
    margin-bottom: 23px;
}


@media(max-width:950px){
    .careers .careers-content .all-jobs .job {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 15px;
        padding-bottom: 30px;
        border-bottom: 1px solid #0000003b;
    }

    .careers .careers-content .all-jobs .job img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        height: 195px;
    }

    .careers .careers-content .all-jobs .job .info p {
        font-size: 15px;
        width: 100%;
        line-height: 18px;
        color: #696969;
    }
    .careers .careers-content .form .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        margin-bottom: 20px;
    }
}