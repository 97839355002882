
.our-products .header{
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.our-products .header h2{
    color: #ababab;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-top: -5px;
}

.our-products .header h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 40px;
    width: 60%;
}


.our-products .product{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
    padding: 21px;
    border-radius: 13px;
    min-height: 337px;
    justify-content: space-around;
    margin-bottom: 60px;
}

.our-products .product img{
    width: 100%;
    height: 150px;
    border-radius: 17px;
    margin-bottom: 16px;
}

.our-products .product h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 12px;
    font-size: 24px;
}

.our-products .product p{
    font-size: 17px;
    line-height: 23px;
    min-height: 136px;
    color: #5a5a5a;
}

.our-products .product a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 9px;
    font-size: 16px;
    color: #1b9cda;
}

@media(max-width:950px){
    .our-products .header h2 {
        color: #ababab;
        font-size: 16px;
        letter-spacing: 3px;
        text-transform: uppercase;
        line-height: 1.43;
        margin-top: -5px;
    }

    .our-products .header h1 {
        color: #333333;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.3;
        font-size: 29px;
        width: 100%;
    }
}