.results-table{
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 25px;
    padding: 20px;
    overflow-x: scroll;
}

.results-table table{
    min-width: 1020px;
}


.results-table table thead th{
    font-size: 15px;
    font-weight: 600;
    margin: 5px;
    padding: 10px;
}



.results-table table tbody td{
    text-align: left;
    padding: 10px;
    margin-bottom: 12px;
    padding-bottom: 11px;
    padding-top: 11px;
    border-top: 1px solid #e3e3e3;
}

.results-table table tbody td img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
}


.results-table table tbody td h2{
    font-size: 16px;
    font-weight: 700;
}
.results-table table tbody td h3{
    font-size: 16px;
    font-weight: 600;
}
.results-table table tbody td h4{
    font-size: 16px;
    background-color: #0000ff3d;
    color: blue;
    border-radius: 25px;
    text-align: center;
    padding: 3px;
}

.results-table table tbody td h4.admin{
    background-color: #ffd70052;
    color: #937c06;
}

.results-table table tbody td h5{
    font-size: 16px;
    background-color: #ff000087;
    color: #a40000;
    border-radius: 25px;
    text-align: center;
    padding: 3px;
}


.results-table table tbody td h5.active{
    background-color: #0080007d;
    color: #005800;
}

.results-table table tbody td h6 {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    text-align: left;
}

.results-table table tbody td h6 span{
    
    padding: 5px 5px 5px 5px;
    background-color: #eee;
}

.results-table table tbody td a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 31px;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
}

.results-table table tbody td a.delete{
    background-color: #cc0303;
}

.results-table table tbody td a.activate{
    background-color: #198723;
}


.results-table table tbody td button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 31px;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    border: none;
    outline: none;
}

.results-table table tbody td button.delete{
    background-color: #cc0303;
}

.results-table table tbody td button.activate{
    background-color: #198723;
}




.edit-form .order .ordered-products{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
}

.edit-form .order .ordered-products .product{
    width: calc(25% - 15px);
    padding: 10px;
    background-color: #eee;
    margin-bottom: 15px;
}

.edit-form .order .ordered-products .product img{
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin-bottom: 12px;
}

.edit-form .order .ordered-products .product h1{
    font-size: 19px;
    font-weight: 700;
    color: #000;
    text-align: center;
}

.edit-form .order .ordered-products .product h2{
    text-align: right;
    font-size: 18px;
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
}




.edit-form .order .ordered-products .product h3{
    font-size: 16px;
}




.edit-form .order .user-info{
    text-align: right;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;
}

.edit-form .order .user-info h2{ 
    width: calc(50% - 40px);
    font-size: 16px;
    background-color: #eee;
    margin: 10px;
    padding: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.edit-form .order .user-info p{ 
    font-size: 20px;
    line-height: 39px;
    margin-top: 27px;
    background-color: #eee;
    min-height: 120px;
    padding: 15px;
    width: 100%;
}