

.blog-page .blog-page-content h1{ 
    font-size: 40px;
    font-weight: 600;
    color: #000;
    margin-bottom: 50px;
}




.blog-page .blog-page-content p{ 
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    color: #121212;
}


.blog-page .blog-page-content img{

    margin-bottom: 30px;
    border-radius: 3px;
}

.blog-page .blog-page-content h2{
    font-size: 25px;
    font-weight: 600;
    color: #120052;
}



@media(max-width:950px){
    
    .blog-page .blog-page-content p{ 
        padding: 0;

    }
}