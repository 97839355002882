
.contact-info-page .info{
    min-height: 200px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.24);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.contact-info-page .info i{
    font-size: 45px;
    color: #1b9cda;
}

.contact-info-page .info h3{
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.contact-info-page .info a{
    color: #4f4f4f;
}