
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  background-color: #fff;
  scroll-behavior: smooth;
  height: 100vh;
}


*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Rubik', sans-serif;
  
}

/* font-family: 'IBM Plex Sans Arabic', sans-serif; */

a{
  text-decoration: none !important;
}

ul{
  list-style: none !important;
  margin: 0 !important;
}


.hide{
  display: none !important;
}




.lang a{
  position: absolute;
  z-index: 5;
  left: 15px;
  top: 34px;
  color: #fff;
  font-size: 21px;
}





.page-header{
  min-height: 451px;
  position: relative;
}

.page-header .overlay{
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #00000087;
}

.page-header img{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.page-header .page-header-content{
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding-top: 135px;

}



.page-header .page-header-content .text h1{
  font-size: 57px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 60px;
  direction: rtl;
}

.page-header .page-header-content .text .links{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.page-header .page-header-content .text .links a{
  font-size: 18px;
  color: #fff;
}

.page-header .page-header-content .text .links i{
  color: #fff;
  font-size: 19px;
}





@media(max-width:950px){

    section {
      overflow-x: hidden;
    }
  .service-text-section .header h1 {
      font-size: 25px !important;

  }

  .page-header .page-header-content .text h1 {
    font-size: 38px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 60px;
    direction: rtl;
    text-align: center;
}
}






#google_translate_element .skiptranslate.goog-te-gadget>span{
  display: none !important; 
}

#google_translate_element .skiptranslate.goog-te-gadget select {
  margin: 4px 0;
  position: absolute !important;
  top: 39% !important;
  transform: translateY(-50%) !important;
  height: 33px;
  border-radius: 15px;
  margin-left: 15px;
  outline: none;
}

#google_translate_element .skiptranslate.goog-te-gadget{
  color: #fff !important;
}