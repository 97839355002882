.landing{
    min-height: 100vh;
    position: relative;
}

.landing>video{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landing .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000e8;
}

.landing .landing-content{
    min-height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.landing .landing-content h1{
    font-weight: 500;
    font-size: 68px;
    letter-spacing: 0;
    line-height: 1.2;
    color: #1b9cda;
}

.landing .landing-content h2{
    color: #ffffff;
    font-weight: 500;
    font-size: 68px;
    letter-spacing: 0;
    line-height: 1.2;
}


.landing .landing-content p{
    color: #ffffff;
    line-height: 32px;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 18px;
    width: 65%;
}


.landing .landing-content>div{
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.landing .landing-content>div a:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #1b9cda;
    color: #fff;
    width: 180px;
    height: 50px;
    border-radius: 50px;
}

.landing .landing-content>div a:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-size: 18px;
}

@media(max-width:950px){
    .landing .landing-content h1 {
        font-weight: 500;
        font-size: 43px;
        letter-spacing: 0;
        line-height: 1.2;
        color: #d2a98e;
    }

    .landing .landing-content h2 {
        color: #ffffff;
        font-weight: 500;
        font-size: 38px;
        letter-spacing: 0;
        line-height: 1.2;
    }


    .landing .landing-content p {
        color: #ffffff;
        line-height: 32px;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 21px;
        width: 100%;
        margin-top: 30px;
    }
}