footer{
    background-color: #000;
    padding-top: 60px;
    padding-bottom: 60px;
}

footer .footer-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}


footer .footer-content .logo img{
    width: 240px;
    height: 150px;
    object-fit: contain;
}


footer .footer-content p{
    line-height: 1.74;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    visibility: visible;
    font-family: "Inter", sans-serif;
    color: #696969;
    color: #fff;
}

footer .footer-content a{
    line-height: 1.74;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    visibility: visible;
    font-family: "Inter", sans-serif;
    color: #fff;
    margin-bottom: 10px;
}

footer .footer-content h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 24px;
    color: #fff;
    margin-left: -7px;
}


footer .footer-content ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    margin-top: 25px !important;
}

footer .copy-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 55px;
}


footer .copy-right h3{
    line-height: 1.74;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    visibility: visible;
    font-family: "Inter", sans-serif;
    color: #fff;
    margin-bottom: 10px;
}

footer .copy-right .socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}



footer .copy-right .socials a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    color: #fff;
}


@media(max-width:950px){
    footer .footer-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        margin-bottom: 32px;
    }

    footer .copy-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 55px;
    }
}