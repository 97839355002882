

.our-services .header h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 31px;
}

.our-services .header h1 span{
    color: #1b9cda;
}

.our-services .header p{
    margin-top: 30px;
    font-size: 14px;
    color: #616161;
    line-height: 23px;
}

.our-services .header div{
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 54px;
}


.our-services .header div a:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 48px;
    background-color: #1b9cda;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
}

.our-services .header div a:nth-child(2){
    color: #1b9cda;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: center;
    font-weight: 500;
}

.our-services .service{
    min-height: 521px;
    padding: 0px;
    background-color: #fff;
    box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
    border-radius: 14px;
    margin-bottom: 30px;
}

.our-services .service img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
}

.our-services .service h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 21px;
    padding: 15px;
}

.our-services .service p{
    line-height: 1.74;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    visibility: visible;
    font-family: "Inter", sans-serif;
    color: #696969;
    position: relative;
    padding: 15px;
}


@media(max-width:950px){
    .our-services .header div {
        margin-top: 17px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin-top: 54px;
        margin-bottom: 50px;
    }
}