.about{
    background-image: url('../images/lines.webp');
    background-position: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;

}


.about .about-content .header{
    margin-bottom: 60px;
}


.about .about-content .header h3{
    color: #ababab;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-top: -5px;
}


.about .about-content .header h1{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 48px;
    width: 70%;
}

.about .about-content .header h1 span{
    color: #1b9cda;
}



.about .about-content .about-img .image{
    position: relative;
}

.about .about-content .about-img .image img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: top;
    border-radius: 6px;
}

.about .about-content .about-img .image video{
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: top;
    border-radius: 6px;
}


.about .about-content .about-text{
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: top;
    border-radius: 6px;
}


.about .about-content .about-img .text{
    width: 100%;
}

.about .about-content .about-img .text p{
    width: 80%;
}
.about .about-content .about-img .image a{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background-color: #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 42px;
    color: #1b9cda;
    outline: 5px solid #fff;
    border: 2px dotted #1b9cda;
}



.about .about-content .about-img .text p{
    font-size: 18px;
    font-weight: 400;
    line-height: 1.67;
    color: #696969;
    padding-left: 16px;
    border-left: 2px solid #1b9cda;
    margin-top: 60px;
}


.about .about-content .about-text{
    padding: 40px;
}

.about .about-content .about-text h2{
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 34px;
}

.about .about-content .about-text p{
    font-size: 18px;
    margin-top: 32px;
    text-align: left;
}

.about .about-content .about-text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background-color: #1b9cda;
    color: #fff;
    margin-top: 50px;
    border-radius: 50px;
}

@media(max-width:950px){
    .about .about-content .header h1 {
        color: #333333;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.3;
        font-size: 29px;
        width: 100%;
    }


    .about .about-content .about-img .image a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 3;
        background-color: #fff;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        font-size: 35px;
        color: #1b9cda;
        outline: 5px solid #fff;
        border: 2px dotted #1b9cda;
    }
}